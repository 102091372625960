import apiClient from '../../utils/apiClient';

export const customBaseQuery = async ({ url, method, body, headers = {}, ...extras }) => {
  try {
    const result = await apiClient({
      url,
      method,
      data: body,
      headers,
      ...extras,
    });
    return { data: result.data };
  } catch (axiosError) {
    return { error: axiosError };
  }
};
