import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { isAuthenticated, user, tokens, permissions = [], snooper = false } = useSelector((state) => state.auth);

  const can = (permission) => {
    return snooper || permissions.includes(permission);
  };

  const role = useMemo(() => {
    return user?.roles?.[0].name;
  }, [user]);

  return { isAuthenticated, user, tokens, permissions, isSuper: snooper, can, role };
};

export default useAuth;
