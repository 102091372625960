import React from 'react'
import { classNames } from '../utils'

const LoadingSpinner = ({ wrapperClasses, spinnerClasses }) => {
  return (
    <div className={classNames(`flex justify-center items-center h-screen`, wrapperClasses)}>
      <div className={classNames('animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-600', spinnerClasses)}></div>
    </div>
  )
}

export default LoadingSpinner
