// tableReducer.js

import { FETCH_TABLE_DATA_REQUEST, FETCH_TABLE_DATA_SUCCESS, FETCH_TABLE_DATA_FAILURE, RESET_TABLE_STATE } from '../constants/tableActionTypes'

const initialState = {
  data: [],
  loading: false,
  error: null,
  pagination: { totalPages: 1, currentPage: 1 },
  extras: {},
}

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TABLE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
        pagination: {},
      }
    case RESET_TABLE_STATE:
      return {
        ...state,
        ...initialState,
      }
    case FETCH_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload?.data ?? [],
        pagination: action.payload?.pagination ?? {},
        extras: action?.payload?.extras ?? {},
      }
    case FETCH_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default tableReducer
