import { SET_LOADING, SET_ERROR, UPDATE_APP_STATE } from '../constants';

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const setError = (errorMessage) => ({
  type: SET_ERROR,
  payload: errorMessage,
});

export const updateAppState = (payload) => (dispatch) =>
  dispatch({
    type: UPDATE_APP_STATE,
    payload,
  });
