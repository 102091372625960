import axios from 'axios'
import errorMapper from './errorMapper'
import { getTokens, setTokens } from '../services/authService'

const BASE_URL = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Map to store cancellation token source for each request
const cancelTokenMap = new Map()

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token')
    const token = `Bearer ${accessToken}`

    // Attach Authorization header
    config.headers.Authorization = token

    // Add cancellation token
    const cancelTokenSource = axios.CancelToken.source()
    config.cancelToken = cancelTokenSource.token

    // Cancel previous request
    if (cancelTokenMap.has(config.url)) {
      cancelTokenMap.get(config.url).cancel('Request canceled by the user.')
    }

    // Store cancellation token source for the current request
    cancelTokenMap.set(config.url, cancelTokenSource)

    return config
  },
  (error) => {
    // Handle request errors, including cancellation
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message)
    } else {
      return Promise.reject(error)
    }
  },
)

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response.data
  },
  async (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      const errorMessage = error.response.data.message || 'An error occurred'
      const statusCode = error.response.status
      const originalRequest = error.config

      if (statusCode === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const { refresh_token } = getTokens()

        if (refresh_token) {
          try {
            const newToken = await axios.post(`${BASE_URL}/auth/refresh-token`, {
              refresh_token,
            })
            setTokens(newToken.data)
            axios.defaults.headers.common.Authorization = 'Bearer ' + newToken.data.access.token
            originalRequest.headers.Authorization = 'Bearer ' + newToken.data.access.token
            const response = await axios(originalRequest)
            return response?.data
          } catch (tokenError) {
            if (tokenError?.response?.status === 401) {
              localStorage.clear()
              window.location.href = '/login'
            }
            return Promise.reject(errorMapper(tokenError))
          }
        }
        return Promise.reject(errorMapper(error))
      }
    } else if (error.request) {
      return Promise.reject(errorMapper(error))
    } else {
      return Promise.reject(errorMapper(error))
    }
    return Promise.reject(errorMapper(error))
  },
)

export default apiClient
