import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import cashTagsApi from '../services/toolkit/cashTagService'
import transactionApi from '../services/toolkit/transactionService'
import userApi from '../services/toolkit/userService'
import notificationApi from '../services/toolkit/notificationService'
const allMiddleware = [cashTagsApi.middleware, transactionApi.middleware, userApi.middleware, notificationApi.middleware]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(allMiddleware),
})

export default store
