// authService.js
import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './customBaseQuery' // Import your custom base query

const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: customBaseQuery, // Use customBaseQuery
  endpoints: (builder) => ({
    addNotification: builder.mutation({
      query: (data) => ({
        url: data.isUpdate ? `/notification/${data.id}` : '/notification/create',
        method: data.isUpdate ? 'PUT' : 'POST',
        body: {
          sourceType: data.sourceType,
          notificationData: data.notification,
        },
      }),
    }),
    updateNotificationStatus: builder.mutation({
      query: (data) => ({
        url: `/notification/status/${data.id}`,
        method: 'PUT',
        body: {
          status: data.status,
          notificationData: data.extras?.notificationData,
        },
      }),
    }),
    getCashTags: builder.query({
      query: () => ({
        url: '/cashtags?limit=100',
        method: 'GET',
      }),
    }),
    getCashtag: builder.query({
      query: (id) => ({
        url: `/cashtags/${id}`,
        method: 'GET',
      }),
    }),
    deleteCashTag: builder.mutation({
      query: (id) => ({
        url: `/cashtags/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useAddNotificationMutation, useUpdateNotificationStatusMutation, useDeleteCashTagMutation, useLazyGetCashTagsQuery, useLazyGetCashtagQuery } = notificationApi

export default notificationApi
