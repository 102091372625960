// authService.js
import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './customBaseQuery' // Import your custom base query

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customBaseQuery, // Use customBaseQuery
  endpoints: (builder) => ({
    activeDeactiveUser: builder.mutation({
      query: ({ id, shouldActivateUser }) => ({
        url: `/users/active-deactive/${id}`,
        method: 'PUT',
        data: { shouldActivateUser },
      }),
    }),
  }),
})

export const { useActiveDeactiveUserMutation } = userApi

export default userApi
