import { SET_LOADING, SET_ERROR, UPDATE_APP_STATE } from '../constants';

const initialState = {
  loading: false,
  error: null,
  message: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APP_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
