import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  UPDATE_CLIENT_STATE,
} from '../constants/clientActionTypes'

const initialState = {
  clients: [],
  client: {},
  loading: false,
  error: null,
  success: undefined,
  dashboard: {},
  amountStats: {
    loading: false,
  },
}

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case UPDATE_CLIENT_REQUEST:
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: undefined,
      }
    case UPDATE_CLIENT_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
        error: null,
      }
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
        error: null,
      }
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        success: 'Successfully updated the client',
        loading: false,
        error: null,
      }
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        success: 'Delete successfully ' + state.payload,
        loading: false,
        error: null,
      }
    case FETCH_CLIENTS_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case UPDATE_CLIENT_FAILURE:
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default clientReducer
