// reducers/pages.reducer.js
import {
  FETCH_PAGES_REQUEST,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_FAILURE,
  CREATE_PAGE_SUCCESS,
  CREATE_PAGE_FAILURE,
  UPDATE_PAGE_SUCCESS,
  DELETE_PAGE_SUCCESS,
  UPDATE_PAGES_STATE,
  CREATE_PAGE_REQUEST,
  UPDATE_PAGE_STATE,
} from '../constants/pageActionsTypes';

const initialState = {
  pages: [],
  loading: false,
  error: null,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PAGES_REQUEST:
    case CREATE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case FETCH_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_PAGES_FAILURE:
    case CREATE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_PAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        pages: action.payload,
      };
    case DELETE_PAGE_SUCCESS:
      return {
        ...state,
        pages: state.pages.filter((page) => page.id !== action.payload),
      };
    case UPDATE_PAGES_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default pageReducer;
