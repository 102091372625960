import { isAuthenticated } from '../../services/authService';
import { LOGIN_SUCCESS, LOGOUT, UPDATE_AUTH_STATE } from '../constants';

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: undefined,
  snooper: false,
  permissions: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case LOGIN_SUCCESS:
      let permissions = [],
        snooper = false;
      action.payload.roles?.forEach((role) => {
        if (role.name === 'admin') {
          snooper = true;
        }
        permissions.push(...role.permissions.map((perm) => perm.name));
      });
      return {
        ...state,
        user: action.payload,
        permissions,
        snooper,
        isAuthenticated: true,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
