export const FETCH_PAGES_REQUEST = 'FETCH_PAGES_REQUEST';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAILURE = 'FETCH_PAGES_FAILURE';
export const UPDATE_PAGES_STATE = 'UPDATE_PAGES_STATE';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAILURE = 'CREATE_PAGE_FAILURE';

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_FAILURE = 'UPDATE_PAGE_FAILURE';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE';
export const UPDATE_PAGE_STATE = 'UPDATE_PAGE_STATE';
