import { Route } from 'react-router-dom'
import { ProtectedRoute } from '../routes/ProtectedRoute'
import React from 'react'
import { format } from 'date-fns'
import errorMapper from './errorMapper'
import { updateAppState } from '../store/actions/appActions'
import { findLastIndex, slice, sumBy, uniq } from 'lodash'
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const getRoute = (route, isFirst) => {
  return (
    <Route path={route.path} index={isFirst} element={route.isProtected ? <ProtectedRoute>{route.component}</ProtectedRoute> : route.component}>
      {route.subRoutes?.map((route, index) => (
        <React.Fragment key={route.name}>{getRoute(route, index === 0)}</React.Fragment>
      ))}
    </Route>
  )
}

export const generateRandomPassword = () => {
  const length = 10
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let password = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    password += charset.charAt(randomIndex)
  }

  return password
}

export const formatErrorMessage = (error) => {
  if (typeof error === 'string') {
    // If the error is already a string, return it as-is
    return error
  } else if (error instanceof Error || error.message) {
    // If the error is an instance of Error, extract its message
    return error.message
  } else if (typeof error === 'object') {
    // If the error is an object, stringify it
    return JSON.stringify(error)
  } else {
    // For any other type of error, return a generic message
    return 'An error occurred.'
  }
}

/**
 * Reusable function to format a date using date-fns library
 * @param {Date} date - The date to format
 * @param {string} dateFormat - The format to apply (e.g., 'yyyy-MM-dd', 'dd MMM yyyy HH:mm:ss', etc.)
 * @returns {string} - The formatted date string
 */
export const formatDate = (date) => {
  try {
    if (!date) {
      throw new Error('Both date and dateFormat parameters are required.')
    }

    return format(new Date(date), 'dd-MM-yyyy hh:mm:ss a')
  } catch (error) {
    console.error('Error formatting date:', error.message)
    return ''
  }
}

export function formatDateForDatetimeLocal(date) {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
}

export const roleBasedDashboardPaths = {
  manager: '/dashboard/manager',
  admin: '/dashboard/default',
  agent: '/dashboard/agent',
  agent_pro: '/dashboard/clients/redeems',
}

export function dispatchError(dispatch, err) {
  const error = errorMapper(err)
  if (error?.message) {
    dispatch(
      updateAppState({
        message: {
          type: 'error',
          message: error.message,
        },
      }),
    )
  }
}

export function dispatchSuccess(dispatch, message) {
  dispatch(
    updateAppState({
      message: {
        type: 'success',
        message,
      },
    }),
  )
}

export function getClientAmountCalculations(amounts = []) {
  const deposits = amounts.filter((transaction) => transaction.amountType === 'Deposit')
  const redeems = amounts.filter((transaction) => transaction.amountType === 'Redeem')
  const convertToNumber = (amount) => parseFloat(amount)
  const convertAndSum = (transactions) => sumBy(transactions, (transaction) => convertToNumber(transaction.amount))

  // Calculate totalDeposits
  const totalDeposits = convertAndSum(deposits)

  // Calculate totalRedeems
  const totalRedeems = convertAndSum(redeems)

  // Calculate totalDepositsFromLastRedeem
  const lastRedeemIndex = findLastIndex(amounts, { amountType: 'Redeem' })
  const depositsFromLastRedeem = slice(amounts, lastRedeemIndex + 1)
  const totalDepositsFromLastRedeem = convertAndSum(depositsFromLastRedeem)
  return {
    totalDeposits,
    totalRedeems,
    totalDepositsFromLastRedeem,
  }
}

export function extractValuesByKey(arr, key) {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    arr[i][key] && result.push(arr[i][key]?.replace(/\t| /g, ''))
  }
  let data = uniq(result)
  return data
}

export function removeUndefinedOrNullProperties(obj) {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeUndefinedOrNullProperties(obj[key]) // Recursively check nested objects
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key] // If the nested object is now empty, delete the key
      }
    }
  }
}
