import * as types from '../constants/userActionTypes';

const initialState = {
  loading: false,
  users: [],
  roles: [],
  user: null,
  error: null,
  success: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.FETCH_USERS_REQUEST:
    case types.DELETE_USER_REQUEST:
    case types.FETCH_USER_REQUEST:
    case types.UPDATE_USER_REQUEST:
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter((user) => user.id !== action.payload),
        error: null,
      };

    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        success: action.payload?.email + ' updated successfully!',
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
        error: null,
        success: action.payload?.email + ' created successfully!',
      };

    case types.FETCH_USERS_FAILURE:
    case types.DELETE_USER_FAILURE:
    case types.FETCH_USER_FAILURE:
    case types.UPDATE_USER_FAILURE:
    case types.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
