// authService.js
import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './customBaseQuery' // Import your custom base query

const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: customBaseQuery, // Use customBaseQuery
  endpoints: (builder) => ({
    updateCashTagStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/clients/update/transaction/${id}`,
        method: 'PUT',
        data: { status },
      }),
    }),
  }),
})

export const { useAddCashTagMutation, useUpdateCashTagStatusMutation, useLazyGetCashTagsQuery } = transactionApi

export default transactionApi
