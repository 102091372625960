// authService.js
import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './customBaseQuery' // Import your custom base query

const cashTagsApi = createApi({
  reducerPath: 'cashTagsApi',
  baseQuery: customBaseQuery, // Use customBaseQuery
  endpoints: (builder) => ({
    addCashTag: builder.mutation({
      query: (data) => ({
        url: data.isUpdate ? `/cashtags/${data.id}` : '/cashtags/create',
        method: data.isUpdate ? 'PUT' : 'POST',
        body: {
          paymentSource: data.paymentSource,
        },
      }),
    }),
    getCashTags: builder.query({
      query: () => ({
        url: '/cashtags?limit=400',
        method: 'GET',
      }),
    }),
    getCashtag: builder.query({
      query: (id) => ({
        url: `/cashtags/${id}`,
        method: 'GET',
      }),
    }),
    deleteCashTag: builder.mutation({
      query: (id) => ({
        url: `/cashtags/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useAddCashTagMutation, useDeleteCashTagMutation, useLazyGetCashTagsQuery, useLazyGetCashtagQuery } = cashTagsApi

export default cashTagsApi
