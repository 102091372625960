// actions/authActions.js

import { LOGIN_SUCCESS, LOGOUT, UPDATE_AUTH_STATE } from '../constants'
import { login as authLogin, fetchUserProfile, logoutUser } from '../../services/authService'
import { setError, setLoading, updateAppState } from './appActions'

export const updateAuthState = (payload) => (dispatch) =>
  dispatch({
    type: UPDATE_AUTH_STATE,
    payload,
  })

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
})

export const logoutSuccess = () => ({
  type: LOGOUT,
})

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AUTH_STATE,
      payload: {
        loading: true,
        error: undefined,
      },
    })
    // Call the authLogin service to authenticate the user
    const response = await authLogin(credentials)
    const { status, data, tokens } = response

    if (status === true) {
      dispatch(loginSuccess(data))
      let urlSearchParams = new URLSearchParams(window.location.search)
      window.history.pushState(null, '', urlSearchParams.has('redirect_url') ? urlSearchParams.get('redirect_url') : '/dashboard')
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AUTH_STATE,
      payload: {
        loading: false,
        error,
      },
    })
    // Handle any errors that occur during the API call
    // Dispatch an action to set the error in the app state
    // dispatch(setError('An error occurred while logging in.'));
  }
}

export const logout = () => async (dispatch) => {
  try {
    await logoutUser()
    dispatch(logoutSuccess())
  } catch (e) {
    console.log('Logout failed:', e)
    dispatch(logoutSuccess())
  }
}

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(
      updateAppState({
        loading: true,
        error: null,
      }),
    )
    // Call the authLogin service to authenticate the user
    const response = await fetchUserProfile()
    setLoading(false)
    dispatch(loginSuccess(response))
    return response
  } catch (error) {
    setError(error)
    dispatch(updateAuthState({ user: {}, isAuthenticated: false }))
    throw error
    // Handle any errors that occur during the API call
    // Dispatch an action to set the error in the app state
    // dispatch(setError('An error occurred while logging in.'));
  }
}
