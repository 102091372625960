// authService.js

import apiClient from '../utils/apiClient'

// Function to perform user login
export const login = async ({ email, password }) => {
  try {
    // Make an API call to the login endpoint using the apiClient
    const response = await apiClient.post('/auth/login', { email, password })
    if (response?.tokens) await setTokens(response?.tokens)
    // Return the response or extract relevant data as needed
    return response
  } catch (error) {
    // Handle and propagate the error as needed
    throw error
  }
}

// Function to perform user registration
export const register = async (data) => {
  try {
    // Make an API call to the register endpoint using the apiClient
    const response = await apiClient.post('/register', data)

    // Return the response or extract relevant data as needed
    return response
  } catch (error) {
    // Handle and propagate the error as needed
    throw error
  }
}

// Function to logout the user
export const logoutUser = async () => {
  try {
    // Make an API call to the logout endpoint using the apiClient
    const response = await apiClient.post('/auth/logout', {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
    })
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    // Return the response or extract relevant data as needed
    return response.data
  } catch (error) {
    // Handle and propagate the error as needed
    throw error
  }
}

export const fetchUserProfile = async () => {
  try {
    const response = await apiClient.get('/auth/profile')
    return response
  } catch (error) {
    return error
  }
}

export const setTokens = async (tokens) => {
  await localStorage.setItem('access_token', tokens.access.token)
  await localStorage.setItem('refresh_token', tokens.refresh.token)
  const accessExpires = new Date(tokens.access.expires).getTime()
  await localStorage.setItem('access_token_expires', accessExpires.toString())
}

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  return localStorage.getItem('access_token') !== null && localStorage.getItem('refresh_token') !== null
}

export const getTokens = () => {
  return {
    refresh_token: localStorage.getItem('refresh_token'),
    access_token: localStorage.getItem('access_token'),
    access_token_expires: localStorage.getItem('access_token_expires'),
  }
}
