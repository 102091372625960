const errorMapper = (errorObject) => {
  const { response: { data } = {}, message: errMessage } = errorObject
  const message = (typeof data === 'string' && data) || data?.result?.description || (Array.isArray(data?.error) && data.error.length > 0 && data.error[0]?.message) || (Array.isArray(data) && data.length > 0 && data[0]?.message) || data?.error || data?.message || data?.description || errMessage
  const statusCode = data?.code || data?.statusCode || data?.result?.statusCode || errorObject.code
  const error = {
    data,
    message,
    statusCode,
  }

  return error
}

export default errorMapper
