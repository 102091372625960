import { Suspense, useEffect } from 'react'
import { useLoaderData, useOutlet, Await } from 'react-router-dom'
import LoadingSpinner from '../components/LoadingSpinner'

export const AuthLayout = () => {
  const outlet = useOutlet()

  const { userPromise } = useLoaderData()

  useEffect(() => {
    userPromise
      .then((res) => {})
      .catch((err) => {
        console.log({ err })
      })
  }, [userPromise])

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Await resolve={userPromise} errorElement={<div severity="error">Something went wrong!</div>}>
        {outlet}
      </Await>
    </Suspense>
  )
}
